import { createAction, createReducer } from '@reduxjs/toolkit'
import set from 'lodash/set'

import {
  CALL_API,
  ApiAction,
  UNKNOWN_ERROR_ID,
} from 'shared/middlewares/api-middleware'

import { push } from 'react-router-redux'

import { showAlert } from 'client/shared/reducers/alert-reducer'
import {
  analyticsEvent,
  ERROR_SHOWN,
} from 'client/shared/reducers/analytics-reducer'
import { externalRedirect } from 'client/shared/reducers/app-reducer'

import urlFor, { prepareQueryString } from 'shared/tools/url-helper'

import { ThunkAction, Dispatch, Action } from 'shared/types/redux'
import {
  CurrentUserSubscriptions,
  AccessLevels,
  Product,
  WebPlan,
  SpecialOfferPlan,
  SpecialOfferError,
  UpgradeOption,
  FamilyMember,
  FamilyInvite,
} from 'client/shared/types/subscription'
import { LoadingState } from 'client/shared/types/loading-state'
import { EMAIL_REGEX } from '../../shared/helpers/text-helpers'
import qs from 'qs'
import isEmpty from 'lodash/isEmpty'
import { FetchMethod } from 'shared/tools/fetch-wrapper'
import omit from 'lodash/omit'

const DEFAULT_LOADING_STATUS = 'not requested'
const LOADING_STATUS = 'loading'
const SUCCESS_LOADING_STATUS = 'success'
const ERROR_LOADING_STATUS = 'error'

const plansLoad = createAction('PLANS_LOAD')
const plansLoadSuccess = createAction('PLANS_LOAD_SUCCESS')
const plansLoadError = createAction('PLANS_LOAD_ERROR')

const yettelHungaryCancel = createAction('YETTEL_HUNGARY_CANCEL')
const yettelHungaryCancelSuccess = createAction('YETTEL_HUNGARY_CANCEL_SUCCESS')
const yettelHungaryCancelError = createAction('YETTEL_HUNGARY_CANCEL_ERROR')

const subscriptionAccessLevelsLoad = createAction(
  'SUBSCRIPTION_ACCESS_LEVELS_LOAD',
)
const subscriptionAccessLevelsLoadSuccess = createAction(
  'SUBSCRIPTION_ACCESS_LEVELS_LOAD_SUCCESS',
)
const subscriptionAccessLevelsLoadError = createAction(
  'SUBSCRIPTION_ACCESS_LEVELS_LOAD_ERROR',
)

export const subscriptionAccessLevelsClear = createAction(
  'SUBSCRIPTION_ACCESS_LEVELS_CLEAR',
)

const subscriptionProductsLoad = createAction('SUBSCRIPTION_PRODUCTS_LOAD')
const subscriptionProductsLoadSuccess = createAction(
  'SUBSCRIPTION_PRODUCTS_LOAD_SUCCESS',
)
const subscriptionProductsLoadError = createAction(
  'SUBSCRIPTION_PRODUCTS_LOAD_ERROR',
)

const subscriptionFeaturedProductsLoad = createAction(
  'SUBSCRIPTION_FEATURED_PRODUCT_LOAD',
)
const subscriptionFeaturedProductsLoadSuccess = createAction(
  'SUBSCRIPTION_FEATURED_PRODUCT_LOAD_SUCCESS',
)
const subscriptionFeaturedProductsLoadError = createAction(
  'SUBSCRIPTION_FEATURED_PRODUCT_LOAD_ERROR',
)

const specialOfferPlanLoad = createAction('SPECIAL_OFFER_PLAN_LOAD')
const specialOfferPlanLoadSuccess = createAction(
  'SPECIAL_OFFER_PLAN_LOAD_SUCCESS',
)
const specialOfferPlanLoadError = createAction('SPECIAL_OFFER_PLAN_LOAD_ERROR')

const currentUserSubscriptionsLoad = createAction(
  'CURRENT_USER_SUBSCRIPTIONS_LOAD',
)
const currentUserSubscriptionsLoadSuccess = createAction(
  'CURRENT_USER_SUBSCRIPTIONS_LOAD_SUCCESS',
)
const currentUserSubscriptionsLoadError = createAction(
  'CURRENT_USER_SUBSCRIPTIONS_LOAD_ERROR',
)

const upgradeCostLoad = createAction('UPGRADE_COSTS_LOAD')
const upgradeCostLoadSuccess = createAction('UPGRADE_COSTS_LOAD_SUCCESS')
const upgradeCostLoadError = createAction('UPGRADE_COSTS_LOAD_ERROR')

const subscriptionDelete = createAction('DELETE_SUBSCRIPTION')
const subscriptionDeleteSuccess = createAction('DELETE_SUBSCRIPTION_SUCCESS')
const subscriptionDeleteError = createAction('DELETE_SUBSCRIPTION_ERROR')

const chargeForRecurrentAction = createAction('CHARGE_FOR_RECURRENT')
const chargeForRecurrentActionSuccess = createAction(
  'CHARGE_FOR_RECURRENT_SUCCESS',
)
const chargeForRecurrentActionError = createAction('CHARGE_FOR_RECURRENT_ERROR')

const changeCardAction = createAction('CHANGE_CARD')
const changeCardActionSucces = createAction('CHANGE_CARD_SUCCESS')
const changeCardActionError = createAction('CHANGE_CARD_ERROR')

const subscriptionApplyOffer = createAction('SUBSCRIPTION_APPLY_OFFER')
const subscriptionApplyOfferSuccess = createAction(
  'SUBSCRIPTION_APPLY_OFFER_SUCCESS',
)
const subscriptionApplyOfferError = createAction(
  'SUBSCRIPTION_APPLY_OFFER_ERROR',
)

// FAMILY SUBSCRIPTION
const familySubscriptionInvitesLoad = createAction(
  'FAMILY_SUBSCRIPTION_INVITES_LOAD',
)
const familySubscriptionInvitesLoadSuccess = createAction(
  'FAMILY_SUBSCRIPTION_INVITES_LOAD_SUCCESS',
)
const familySubscriptionInvitesLoadError = createAction(
  'FAMILY_SUBSCRIPTION_INVITES_LOAD_ERROR',
)

const familySubscriptionMembersLoad = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_LOAD',
)
const familySubscriptionMembersLoadSuccess = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_LOAD_SUCCESS',
)
const familySubscriptionMembersLoadError = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_LOAD_ERROR',
)

const familySubscriptionMembersCreateInvitationAction = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_CREATE_INVITATION',
)
const familySubscriptionMembersCreateInvitationActionSuccess = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_CREATE_INVITATION_SUCCESS',
)
const familySubscriptionMembersCreateInvitationActionError = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_CREATE_INVITATION_ERROR',
)

const familySubscriptionMembersRemoveAction = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_REMOVE',
)
const familySubscriptionMembersRemoveActionSuccess = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_REMOVE_SUCCESS',
)
const familySubscriptionMembersRemoveActionError = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_REMOVE_ERROR',
)

const familySubscriptionMembersCancelInvitationAction = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_CANCEL_INVITATION',
)
const familySubscriptionMembersCancelInvitationActionSuccess = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_CANCEL_INVITATION_SUCCESS',
)
const familySubscriptionMembersCancelInvitationActionError = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_CANCEL_INVITATION_ERROR',
)

const familySubscriptionMembersAcceptInvitationAction = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_ACCEPT_INVITATION',
)
const familySubscriptionMembersAcceptInvitationActionSuccess = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_ACCEPT_INVITATION_SUCCESS',
)
const familySubscriptionMembersAcceptInvitationActionError = createAction(
  'FAMILY_SUBSCRIPTION_MEMBERS_ACCEPT_INVITATION_ERROR',
)

const familySubscriptionInviteLoad = createAction(
  'FAMILY_SUBSCRIPTION_INVITATION_LOAD',
)
const familySubscriptionInviteSuccess = createAction(
  'FAMILY_SUBSCRIPTION_INVITATION_SUCCESS',
)
const familySubscriptionInviteError = createAction(
  'FAMILY_SUBSCRIPTION_INVITATION_ERROR',
)

const familySubscriptionResetErrorMessageAction = createAction(
  'FAMILY_SUBSCRIPTION_RESET_ERROR_MESSAGE',
)

export type UpgradeCostsPayload = {
  recurrentUuid: string // id of recurrent subscription from which to upgrade
  planIds: string[] // ids of plans that can be upgraded to
  productId: string
}

export type State = {
  loadingStates: {
    userAccessLevels: LoadingState
    products: LoadingState
    featuredProduct: LoadingState
    userSubscriptions: LoadingState
    changeCard: LoadingState
    deleteSubscription: LoadingState
    chargeForRecurrent: LoadingState
    upgradeCosts: LoadingState
    specialOffer: LoadingState
    familySubscriptionMembers: LoadingState
    familySubscriptionInvites: LoadingState
    familySubscriptionCreateInvitation: LoadingState
    familySubscriptionRemoveMember: LoadingState
    familySubscriptionInvitation: LoadingState
  }
  plans: WebPlan[]
  products: Product[] | null | undefined
  featuredProduct: Product | null | undefined // well, not really Product, but for all intents and purposes it's close
  specialOffer: {
    [key: string]: {
      error: SpecialOfferError | null | undefined
      plans: SpecialOfferPlan[] | null | undefined
    }
  }
  userAccessLevels: AccessLevels
  userSubscriptions: CurrentUserSubscriptions
  upgradeSubscription: {
    recurrentUuid: string | null | undefined
    upgradeOptions: UpgradeOption[]
  }
  familySubscription: {
    members: FamilyMember[]
    invites: FamilyInvite[]
    error: string | null | undefined
    invitation: FamilyInvite | null | undefined
  }
}

export function loadUserSubscriptions(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/subscriptions',
      types: [
        currentUserSubscriptionsLoad,
        currentUserSubscriptionsLoadSuccess,
        currentUserSubscriptionsLoadError,
      ],
    },
  }
}

export function loadUserAccessLevels(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/access_levels',
      types: [
        subscriptionAccessLevelsLoad,
        subscriptionAccessLevelsLoadSuccess,
        subscriptionAccessLevelsLoadError,
      ],
    },
  }
}

export function postApplyOffer(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/subscriptions/retention_flow_special_offer',
      types: [
        subscriptionApplyOffer,
        subscriptionApplyOfferSuccess,
        subscriptionApplyOfferError,
      ],
      options: {
        method: 'post' as FetchMethod,
      },
      onSuccess: dispatch =>
        dispatch(push(urlFor.subscriptionKind('master', 'plans'))),
    },
  }
}

export function loadFamilyMembers(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/family/members',
      types: [
        familySubscriptionMembersLoad,
        familySubscriptionMembersLoadSuccess,
        familySubscriptionMembersLoadError,
      ],
    },
  }
}
export function loadFamilyInvites(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/family/invites',
      types: [
        familySubscriptionInvitesLoad,
        familySubscriptionInvitesLoadSuccess,
        familySubscriptionInvitesLoadError,
      ],
    },
  }
}

export function createFamilyMemberInvitation(
  loginOrEmail: string,
): ThunkAction {
  return async dispatch => {
    const value = loginOrEmail.trim()

    let data = {}

    if (isEmpty(value)) {
      data = {}
    } else if (EMAIL_REGEX.test(value)) {
      data = { email: value }
    } else {
      data = { login: value }
    }

    await dispatch({
      [CALL_API]: {
        endpoint: '/p/api/v5/profile/family/invites',
        options: {
          method: 'post',
          data,
        },
        onSuccess: _dispatch => {
          _dispatch(loadFamilyInvites())
        },
        types: [
          familySubscriptionMembersCreateInvitationAction,
          familySubscriptionMembersCreateInvitationActionSuccess,
          familySubscriptionMembersCreateInvitationActionError,
        ],
      },
    })
  }
}

export function removeFamilyMember(id: string): ThunkAction {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        endpoint: `/p/api/v5/profile/family/members/${id}`,
        options: {
          method: 'delete',
        },
        onSuccess: _dispatch => {
          _dispatch(loadFamilyMembers())
        },
        types: [
          familySubscriptionMembersRemoveAction,
          familySubscriptionMembersRemoveActionSuccess,
          familySubscriptionMembersRemoveActionError,
        ],
      },
    })
  }
}

export function cancelFamilyMemberInvitation(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/family/invites/${uuid}`,
      options: {
        method: 'delete',
      },
      onSuccess: _dispatch => {
        _dispatch(loadFamilyInvites())
      },
      types: [
        familySubscriptionMembersCancelInvitationAction,
        familySubscriptionMembersCancelInvitationActionSuccess,
        familySubscriptionMembersCancelInvitationActionError,
      ],
    },
  }
}

export function getFamilySubscriptionInvite(uuid: string): ThunkAction {
  return async (dispatch: Dispatch) => {
    await dispatch({
      [CALL_API]: {
        endpoint: `/p/api/v5/family/invite?${qs.stringify({
          uuid,
        })}`,
        types: [
          familySubscriptionInviteLoad,
          familySubscriptionInviteSuccess,
          familySubscriptionInviteError,
        ],
      },
    })
  }
}

export function acceptFamilyMemberInvitation(uuid: string): ThunkAction {
  return async (dispatch: Dispatch) => {
    await dispatch({
      [CALL_API]: {
        endpoint: `/p/api/v5/profile/family/invite/accept`,
        options: {
          method: 'post',
          data: { uuid },
        },
        onSuccess: _dispatch => {
          _dispatch(loadUserAccessLevels())
          _dispatch(loadFamilyMembers())
          _dispatch(loadFamilyInvites())
        },
        types: [
          familySubscriptionMembersAcceptInvitationAction,
          familySubscriptionMembersAcceptInvitationActionSuccess,
          familySubscriptionMembersAcceptInvitationActionError,
        ],
      },
    })
  }
}

export function resetFamilySubscriptionErrorMessage(): Action {
  return {
    type: familySubscriptionResetErrorMessageAction,
  }
}

// eslint-disable-next-line func-names
export const loadPlans = function (): ThunkAction {
  return async (dispatch: Dispatch) => {
    const uri = `/p/api/v5/payments/plans/web`

    await dispatch({
      [CALL_API]: {
        endpoint: uri,
        types: [plansLoad, plansLoadSuccess, plansLoadError],
      },
    })
  }
}

export const loadProducts = (offerRegularPlan?: boolean): ApiAction => ({
  [CALL_API]: {
    endpoint: `/p/api/v5/payments/products/web${
      offerRegularPlan ? '?with_trials=false' : ''
    }`,
    modifyResponse: response => ({ products: prepareLoadedProducts(response) }),
    types: [
      subscriptionProductsLoad,
      subscriptionProductsLoadSuccess,
      subscriptionProductsLoadError,
    ],
  },
})

export const loadFeaturedProduct = (): ApiAction => ({
  [CALL_API]: {
    endpoint: '/p/api/v5/payments/featured_product/web',
    modifyResponse: response => ({
      featured_product: prepareLoadedProducts(response),
    }),
    types: [
      subscriptionFeaturedProductsLoad,
      subscriptionFeaturedProductsLoadSuccess,
      subscriptionFeaturedProductsLoadError,
    ],
  },
})

export const cancelYettelHungary = function (userId: number): ThunkAction {
  return async (dispatch: Dispatch) => {
    const uri = `/p/api/v5/partners/yettel_hungary/subscription`

    await dispatch({
      [CALL_API]: {
        endpoint: uri,
        options: {
          method: 'delete',
          data: { id: userId },
        },
        onSuccess: _dispatch => {
          _dispatch(loadUserSubscriptions())
          _dispatch(loadProducts())
        },
        types: [
          yettelHungaryCancel,
          yettelHungaryCancelSuccess,
          yettelHungaryCancelError,
        ],
      },
    })
  }
}

export function loadThreeMonthsPlan(
  campaign?: string | null | undefined,
  params: { variant?: string } = {},
): ApiAction {
  const fetchOptions: { data?: any } = {}

  if (params.variant) {
    fetchOptions.data = { variant: params.variant }
  }
  const additionalPlanProperties = {
    // id: params.variant || 'cheap_trial',
    id: params.variant || 'march8_rs_2024',
    system: 'trial_3_months',
    trial: true,
    plansList: [],
  }

  const isProlece2024Plan = campaign === 'prolece2024'

  let endpoint = '/p/api/v5/payments/plans/cheap_trial'
  if (isProlece2024Plan)
    endpoint = '/p/api/v5/payments/plans/cheap_trial?variant=march8_rs_2024'

  return {
    [CALL_API]: {
      endpoint,
      options: fetchOptions,
      preserveError: true,
      modifyResponse: response => {
        const newPlans = isProlece2024Plan
          ? response.plans.sort((a, b) => a.price - b.price)
          : []

        let newPlan
        if (isProlece2024Plan) newPlan = { ...newPlans[0] }
        else newPlan = { ...response.plan }

        return {
          plan: { ...newPlan, ...additionalPlanProperties },
          plansList: newPlans,
        }
      },
      types: [
        specialOfferPlanLoad,
        specialOfferPlanLoadSuccess,
        specialOfferPlanLoadError,
      ],
    },
    name: campaign,
  }
}

function getCorrectSubscriptionRecurrentType(
  type: string,
): string | null | undefined {
  switch (type) {
    case 'tele2ru':
    case 'tele2rurecurrent':
      return 'tele2_ru'
    case 'android_inapp':
      return 'android'
    case 'androidinapp':
      return 'android'
    case 'paypal':
      return 'paypal'
    case 'cards':
      return 'stripe'
    case 'mtn_ghana':
      return 'mtn_ghana'
  }
}

function isMtnGhana(type: string) {
  return type === 'mtn_ghana'
}

export function deleteSubscription(
  type: string,
  kind: string,
  cb?: () => void,
) {
  return async (dispatch: Dispatch) => {
    const normalizedType = getCorrectSubscriptionRecurrentType(type)

    if (!normalizedType) {
      dispatch(showAlert('error', { message: 'errors.unknown' }))
      throw new Error(
        `There is no unsubscribe url for a subscription of a "${type}" type`,
      )
    }

    let endpoint = ['stripe', 'android', 'paypal'].includes(normalizedType)
      ? `/p/api/v5/payments/subscriptions/${normalizedType}/${kind}`
      : `/p/payments/${normalizedType}`

    if (isMtnGhana(normalizedType)) {
      endpoint = '/p/api/v5/mtn_ghana/subscriptions/cancel'
    }

    return await dispatch({
      [CALL_API]: {
        endpoint,
        options: {
          method: isMtnGhana(normalizedType) ? 'post' : 'delete',
          dontParse: true,
        },
        onSuccess: _dispatch => {
          _dispatch(loadUserSubscriptions())
          _dispatch(loadProducts())
          if (typeof cb === 'function') {
            cb()
          }
        },
        types: [
          subscriptionDelete,
          subscriptionDeleteSuccess,
          subscriptionDeleteError,
        ],
      },
    })
  }
}

export function chargeForRecurrent(uuid: string, path: string): ApiAction {
  const endpoint = `/p/api/v5/payments/stripe/subscriptions/${uuid}/charge`

  return {
    [CALL_API]: {
      endpoint,
      options: {
        method: 'post',
      },
      onSuccess: (dispatch, getState, response) => {
        const { redirect_url: redirectUrl } = response
        if (redirectUrl) {
          dispatch(externalRedirect(redirectUrl))
        } else {
          const state = getState()
          dispatch(
            push(
              urlFor.subscriptionSuccess({
                path,
                type: 'subsc',
                ...state?.app?.storedQuery,
              }),
            ),
          )
        }
      },
      types: [
        chargeForRecurrentAction,
        chargeForRecurrentActionSuccess,
        chargeForRecurrentActionError,
      ],
    },
  }
}

export function changeCard(
  recurrentUuid: string,
  sourceId: string,
  cb: () => void,
): ApiAction {
  const endpoint = `/p/api/v5/payments/stripe/subscriptions/${recurrentUuid}/default_card`

  return {
    [CALL_API]: {
      endpoint,
      options: {
        method: 'post',
        data: {
          source: sourceId,
        },
      },
      onSuccess: dispatch => {
        dispatch(loadUserSubscriptions())
        dispatch(
          showAlert('success', {
            message: 'subscription.payment_information_updated',
          }),
        )
        cb()
      },
      onError: (dispatch, getState, error) => {
        let errorMessage = ''
        if (typeof error === 'string') {
          errorMessage = error
        } else if (error && typeof error === 'object') {
          errorMessage = error.error?.message
        } else {
          errorMessage = UNKNOWN_ERROR_ID
        }

        dispatch(
          analyticsEvent(ERROR_SHOWN, {
            client_message: errorMessage,
            endpoint,
          }),
        )
      },
      types: [changeCardAction, changeCardActionSucces, changeCardActionError],
    },
  }
}

export function getUpgradeCosts({
  recurrentUuid,
  planIds,
  productId,
}: UpgradeCostsPayload): ApiAction {
  const path = `/p/api/v5/payments/stripe/subscriptions/${recurrentUuid}/upgrade_costs`
  const query = prepareQueryString({ plan_uuids: planIds })
  const endpoint = `${path}?${query}`

  return {
    [CALL_API]: {
      endpoint,
      options: {
        method: 'get',
      },
      normalize: ({ upgrade_costs }) => {
        const upgradeCosts = upgrade_costs.map(option =>
          set(option, 'plan.productId', productId),
        )
        return {
          payload: {
            recurrentUuid,
            upgradeOptions: upgradeCosts,
          },
        }
      },
      types: [upgradeCostLoad, upgradeCostLoadSuccess, upgradeCostLoadError],
    },
  }
}

// this transformation is intended to add product id to the
function prepareLoadedProducts(data) {
  const transformButton = (button, productId) => ({
    ...button,
    plans: button.plans.map(plan => ({ ...plan, productId })),
  })
  const transformProduct = product => ({
    ...product,
    button: product.buttons
      ? transformButton(product.buttons[0], product.id)
      : transformButton(product.button, product.id),
  })

  if (data.featured_product) {
    return transformProduct(data.featured_product)
  } else if (data.products) {
    return data.products.map(transformProduct)
  }
}

const initialState = {
  loadingStates: {
    userAccessLevels: DEFAULT_LOADING_STATUS,
    products: DEFAULT_LOADING_STATUS,
    featuredProduct: DEFAULT_LOADING_STATUS,
    userSubscriptions: DEFAULT_LOADING_STATUS,
    changeCard: DEFAULT_LOADING_STATUS,
    deleteSubscription: DEFAULT_LOADING_STATUS,
    chargeForRecurrent: DEFAULT_LOADING_STATUS,
    upgradeCosts: DEFAULT_LOADING_STATUS,
    familySubscriptionMembers: DEFAULT_LOADING_STATUS,
    familySubscriptionCreateInvitation: DEFAULT_LOADING_STATUS,
    familySubscriptionRemoveMember: DEFAULT_LOADING_STATUS,
    familySubscriptionCancelInvitation: DEFAULT_LOADING_STATUS,
    familySubscriptionAcceptInvitation: DEFAULT_LOADING_STATUS,
    familySubscriptionInvitation: DEFAULT_LOADING_STATUS,
  },
  loading: false,
  error: undefined,
  plans: [],
  products: null,
  featuredProduct: null,
  upgradeOptions: [],
  specialOffer: {},

  familySubscription: {
    members: [],
    invites: [],
    error: null,
    invitation: undefined,
  },
  userAccessLevels: {
    expiration_dates: [],
    active_recurrents: [],
  },
  userSubscriptions: {
    recurrents: [],
    non_recurrents: [],
  },
  upgradeSubscription: {
    recurrentUuid: null,
    upgradeOptions: [],
  },
}

const subscription = createReducer(initialState, {
  [(currentUserSubscriptionsLoad as unknown) as string]: state =>
    set(state, 'loadingStates.userSubscriptions', LOADING_STATUS),
  [(currentUserSubscriptionsLoadSuccess as unknown) as string]: (
    state,
    { payload },
  ) => {
    set(state, 'loadingStates.userSubscriptions', SUCCESS_LOADING_STATUS)
    const _payload = {
      non_recurrents: payload.subscriptions.non_recurrents,
      recurrents: payload.subscriptions.recurrents.map(recurrent =>
        omit(recurrent, 'stripe_extended_external_details.items'),
      ),
    }

    state.userSubscriptions = _payload
  },
  [(currentUserSubscriptionsLoadError as unknown) as string]: state =>
    set(state, 'loadingStates.userSubscriptions', ERROR_LOADING_STATUS),
  [(subscriptionAccessLevelsLoad as unknown) as string]: state =>
    set(state, 'loadingStates.userAccessLevels', LOADING_STATUS),
  [(subscriptionAccessLevelsLoadError as unknown) as string]: state =>
    set(state, 'loadingStates.userAccessLevels', ERROR_LOADING_STATUS),
  [(subscriptionAccessLevelsLoadSuccess as unknown) as string]: (
    state,
    { payload },
  ) => {
    set(state, 'loadingStates.userAccessLevels', SUCCESS_LOADING_STATUS)
    state.userAccessLevels = payload.access_levels
  },
  [(subscriptionAccessLevelsClear as unknown) as string]: state => {
    state.userAccessLevels = {
      expiration_dates: [],
      active_recurrents: [],
    }
  },
  [(subscriptionProductsLoad as unknown) as string]: state =>
    set(state, 'loadingStates.products', LOADING_STATUS),
  [(subscriptionProductsLoadError as unknown) as string]: state =>
    set(state, 'loadingStates.products', ERROR_LOADING_STATUS),
  [(subscriptionProductsLoadSuccess as unknown) as string]: (
    state,
    { payload },
  ) => {
    set(state, 'loadingStates.products', SUCCESS_LOADING_STATUS)
    state.products = payload.products
  },
  [(subscriptionFeaturedProductsLoad as unknown) as string]: state =>
    set(state, 'loadingStates.featuredProduct', LOADING_STATUS),
  [(subscriptionFeaturedProductsLoadError as unknown) as string]: state =>
    set(state, 'loadingStates.featuredProduct', ERROR_LOADING_STATUS),
  [(subscriptionFeaturedProductsLoadSuccess as unknown) as string]: (
    state,
    { payload },
  ) => {
    set(state, 'loadingStates.featuredProduct', SUCCESS_LOADING_STATUS)
    state.featuredProduct = payload.featured_product
  },
  [(plansLoadSuccess as unknown) as string]: (state, { payload }) => {
    state.plans = payload.plans
  },
  [(plansLoadError as unknown) as string]: state => {
    state.error = ERROR_LOADING_STATUS
    state.plans = []
  },
  [(plansLoad as unknown) as string]: state =>
    set(state, 'loadingStates.plans', LOADING_STATUS),
  [(specialOfferPlanLoad as unknown) as string]: (state, { payload }) =>
    set(state, `loadingStates[${payload.name}]`, LOADING_STATUS),
  [(specialOfferPlanLoadSuccess as unknown) as string]: (
    state,
    { payload },
  ) => {
    set(state, `loadingStates[${payload.name}]`, SUCCESS_LOADING_STATUS)
    const fetchedPlans = state.specialOffer[payload.name]?.plans
    state.specialOffer = {
      ...state.specialOffer,
      [payload.name]: {
        error: null,
        plan: payload.plan,
        plansList: payload.plansList,
        plans: fetchedPlans?.length
          ? [...fetchedPlans, payload.plan]
          : [payload.plan],
      },
    }
  },
  [(specialOfferPlanLoadError as unknown) as string]: (state, { payload }) => {
    set(state, `loadingStates[${payload.name}]`, ERROR_LOADING_STATUS)
    state.specialOffer = {
      ...state.specialOffer,
      [payload.name]: {
        plans: [],
        error: payload.error,
      },
    }
  },
  [(upgradeCostLoad as unknown) as string]: state => {
    set(state.loadingStates, 'upgradeCosts', LOADING_STATUS)
    state.upgradeSubscription = { ...initialState.upgradeSubscription }
  },
  [(upgradeCostLoadSuccess as unknown) as string]: (state, { payload }) => {
    set(state.loadingStates, 'upgradeCosts', SUCCESS_LOADING_STATUS)
    state.upgradeSubscription = payload.payload
  },
  [(upgradeCostLoadError as unknown) as string]: state => {
    set(state.loadingStates, 'upgradeCosts', ERROR_LOADING_STATUS)
  },
  [(changeCardAction as unknown) as string]: state =>
    set(state, 'loadingStates.changeCard', LOADING_STATUS),
  [(changeCardActionSucces as unknown) as string]: state =>
    set(state, 'loadingStates.changeCard', SUCCESS_LOADING_STATUS),
  [(changeCardActionError as unknown) as string]: state =>
    set(state, 'loadingStates.changeCard', ERROR_LOADING_STATUS),
  [(subscriptionDelete as unknown) as string]: state =>
    set(state, 'loadingStates.deleteSubscription', LOADING_STATUS),
  [(subscriptionDeleteSuccess as unknown) as string]: state =>
    set(state, 'loadingStates.deleteSubscription', SUCCESS_LOADING_STATUS),
  [(subscriptionDeleteError as unknown) as string]: state =>
    set(state, 'loadingStates.deleteSubscription', ERROR_LOADING_STATUS),
  [(yettelHungaryCancel as unknown) as string]: state =>
    set(state, 'loadingStates.deleteSubscription', LOADING_STATUS),
  [(yettelHungaryCancelSuccess as unknown) as string]: state =>
    set(state, 'loadingStates.deleteSubscription', SUCCESS_LOADING_STATUS),
  [(yettelHungaryCancelError as unknown) as string]: state =>
    set(state, 'loadingStates.deleteSubscription', ERROR_LOADING_STATUS),
  [(chargeForRecurrentAction as unknown) as string]: state =>
    set(state, 'loadingStates.chargeForRecurrent', LOADING_STATUS),
  [(chargeForRecurrentActionSuccess as unknown) as string]: state =>
    set(state, 'loadingStates.chargeForRecurrent', SUCCESS_LOADING_STATUS),
  [(chargeForRecurrentActionError as unknown) as string]: state =>
    set(state, 'loadingStates.chargeForRecurrent', ERROR_LOADING_STATUS),

  // FAMILY SUBSCRIPTION
  [(familySubscriptionMembersLoad as unknown) as string]: state => {
    set(state, 'loadingStates.familySubscriptionMembers', LOADING_STATUS)
  },
  [(familySubscriptionMembersLoadSuccess as unknown) as string]: (
    state,
    { payload },
  ) => {
    set(
      state,
      'loadingStates.familySubscriptionMembers',
      SUCCESS_LOADING_STATUS,
    )
    state.familySubscription.members = payload.members
    state.familySubscription.error = null
  },
  [(familySubscriptionMembersLoadError as unknown) as string]: state => {
    set(state, 'loadingStates.familySubscriptionMembers', ERROR_LOADING_STATUS)
  },

  [(familySubscriptionMembersRemoveAction as unknown) as string]: state =>
    set(state, 'loadingStates.familySubscriptionRemoveMember', LOADING_STATUS),
  [(familySubscriptionMembersRemoveActionError as unknown) as string]: state =>
    set(
      state,
      'loadingStates.familySubscriptionRemoveMember',
      ERROR_LOADING_STATUS,
    ),
  [(familySubscriptionMembersRemoveActionSuccess as unknown) as string]: state => {
    set(
      state,
      'loadingStates.familySubscriptionRemoveMember',
      SUCCESS_LOADING_STATUS,
    )
  },

  // FAMILY INVITATIONS
  [(familySubscriptionInvitesLoad as unknown) as string]: state => {
    set(state, 'loadingStates.familySubscriptionInvites', LOADING_STATUS)
  },
  [(familySubscriptionInvitesLoadSuccess as unknown) as string]: (
    state,
    { payload },
  ) => {
    set(
      state,
      'loadingStates.familySubscriptionInvites',
      SUCCESS_LOADING_STATUS,
    )
    state.familySubscription.invites = payload.invites
    state.familySubscription.error = null
  },
  [(familySubscriptionInvitesLoadError as unknown) as string]: state => {
    set(state, 'loadingStates.familySubscriptionInvites', ERROR_LOADING_STATUS)
  },

  [(familySubscriptionMembersCreateInvitationAction as unknown) as string]: state => {
    state.familySubscription.invitation = null
    set(
      state,
      'loadingStates.familySubscriptionCreateInvitation',
      LOADING_STATUS,
    )
  },
  [(familySubscriptionMembersCreateInvitationActionError as unknown) as string]: (
    state,
    { payload },
  ) => {
    set(
      state,
      'loadingStates.familySubscriptionCreateInvitation',
      ERROR_LOADING_STATUS,
    )
    state.familySubscription = {
      ...state.familySubscription,
      error: payload.error.message || 'UNKNOWN',
    }
  },

  [(familySubscriptionMembersCreateInvitationActionSuccess as unknown) as string]: (
    state,
    { payload },
  ) => {
    set(
      state,
      'loadingStates.familySubscriptionCreateInvitation',
      SUCCESS_LOADING_STATUS,
    )
    state.familySubscription = {
      ...state.familySubscription,
      invitation: payload.invite,
    }
  },

  [(familySubscriptionMembersCancelInvitationAction as unknown) as string]: state =>
    set(
      state,
      'loadingStates.familySubscriptionCancelInvitation',
      LOADING_STATUS,
    ),
  [(familySubscriptionMembersCancelInvitationActionError as unknown) as string]: state =>
    set(
      state,
      'loadingStates.familySubscriptionCancelInvitation',
      ERROR_LOADING_STATUS,
    ),
  [(familySubscriptionMembersCancelInvitationActionSuccess as unknown) as string]: state => {
    set(
      state,
      'loadingStates.familySubscriptionCancelInvitation',
      SUCCESS_LOADING_STATUS,
    )
  },
  // invitation flow: accept
  [(familySubscriptionMembersAcceptInvitationAction as unknown) as string]: state => {
    set(
      state,
      'loadingStates.familySubscriptionAcceptInvitation',
      LOADING_STATUS,
    )
    state.familySubscription.error = null
  },
  [(familySubscriptionMembersAcceptInvitationActionError as unknown) as string]: (
    state,
    { payload },
  ) => {
    set(
      state,
      'loadingStates.familySubscriptionAcceptInvitation',
      ERROR_LOADING_STATUS,
    )
    state.familySubscription = {
      ...state.familySubscription,
      error: payload.error.message,
    }
  },

  [(familySubscriptionMembersAcceptInvitationActionSuccess as unknown) as string]: state => {
    set(
      state,
      'loadingStates.familySubscriptionAcceptInvitation',
      SUCCESS_LOADING_STATUS,
    )
    state.familySubscription.error = null
  },
  // invitation flow : get
  [(familySubscriptionInviteLoad as unknown) as string]: state => {
    set(state, 'loadingStates.familySubscriptionInvitation', LOADING_STATUS)
    state.familySubscription.invitation = null
  },
  [(familySubscriptionInviteError as unknown) as string]: state =>
    set(
      state,
      'loadingStates.familySubscriptionInvitation',
      ERROR_LOADING_STATUS,
    ),
  [(familySubscriptionInviteSuccess as unknown) as string]: (
    state,
    { payload },
  ) => {
    set(
      state,
      'loadingStates.familySubscriptionInvitation',
      SUCCESS_LOADING_STATUS,
    )
    state.familySubscription.invitation = payload.invite
  },

  [(familySubscriptionResetErrorMessageAction as unknown) as string]: state => {
    state.familySubscription = {
      ...state.familySubscription,
      error: null,
    }
  },
})

export default subscription
