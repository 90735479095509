import merge from 'lodash/merge'
import fetch from 'isomorphic-fetch'
import omit from 'lodash/omit'
import snakeCase from 'lodash/snakeCase'

import env from 'env'
import reqHandler from 'shared/tools/req-handler'
import { prepareQueryString } from 'shared/tools/url-helper'
import pickAndMapKeys from 'shared/tools/utilities/pick-and-map-keys'

interface CustomError extends Error {
  response?: string
}

export type FetchMethod = 'get' | 'post' | 'delete' | 'put' | 'GET'

export type FetchOptions = {
  state?: Record<string, any>
  isMultipart?: boolean
  fingerprint?: string
  ignoreError?: boolean
  method?: FetchMethod
  data?: Record<string, any>
  dontParse?: boolean
  body?: Record<string, any>
  cors?: string
  mode?: string
}

interface FetchWrapper {
  fetch(uri: string, options: FetchOptions): Promise<any>
  getDefaultOptions: (fetchOptions: FetchOptions) => void
  normalizeFetchMethod: (fetchOptions: FetchOptions) => void
  withoutExtraFields: (fetchOptions: FetchOptions) => void
  getDataAsJson: (data: Record<string, any>) => string
  getCompleteUri: (uri: string, options: FetchOptions) => string
  getCompleteOptions: (fetchOptions: FetchOptions) => FetchOptions
  checkResponse: (res, options) => Promise<any>
}

const location = env.isClient() ? window.location : null

// named fetchWrapper module can be used for testing
export const fetchWrapper = (_fetch, is_client = IS_CLIENT): FetchWrapper => ({
  getDefaultOptions(options) {
    const { state, isMultipart, fingerprint } = options
    let headers: { [key: string]: string | number } = {}

    if (!isMultipart) {
      headers = {
        // headers’ names are case-insensitive, so no reason to case them,
        // see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers
        accept: 'application/json',
        'content-type': 'application/json',
      }
    }

    if (!is_client && state) {
      const req = {
        headers: state.app.headers,
        connection: state.app.connection,
        hostname: state.app.hostname,
      }

      Object.assign(
        headers, // we’ve rewritten some headers for requests with non-multipart options
        omit(req.headers, isMultipart ? [] : Object.keys(headers)),
      )
      headers.cookie = reqHandler.getCookies(req)
      headers['accept-language'] = reqHandler.getAcceptLanguage(req)
      headers['user-agent'] = reqHandler.getUserAgent(req)
      headers['x-forwarded-for'] = reqHandler.getRemoteAddress(req)
      headers['x-request-id'] = reqHandler.getRequestID(req)
      headers.host = req.hostname
      // skip checking csrf from vanguard server
      headers['x-vanguard-ssr-request'] = 1
    }

    if (state) {
      headers = {
        ...headers,
        'x-csrf-token': state.app.csrfToken,
      }

      if (fingerprint && fingerprint.length > 0)
        headers['x-device-fingerprint'] = fingerprint
    }

    return {
      headers,
      credentials: 'same-origin', // send cookies
    }
  },

  normalizeFetchMethod(options) {
    // while some fetch methods can be written both in upper- and in lowercase,
    // some methods (such as PATCH) _have_ to be written in uppercase;
    // therefore, normalize all methods to uppercase
    const { method = 'GET' } = options
    return { ...options, method: method.toUpperCase() }
  },

  withoutExtraFields(options) {
    // remove the `data` field from the `options` object
    // (by this point, we have either passed the contents of the data field in the `body` field
    // or have used them to create url)
    return omit(options, ['data', 'cors'])
  },

  getDataAsJson(data) {
    return JSON.stringify(data)
  },

  getCompleteUri(uri, options) {
    const { data, method, cors } = options
    let params = ''

    if (!cors) {
      const address = env.isClient()
        ? (location as Location).origin
        : SERVER_ADDRESS
      uri = `${address}${uri}`
    }

    if (data && Object.keys(data).length > 0 && method === 'GET') {
      params = `${uri.includes('?') ? '&' : '?'}${prepareQueryString(data)}`
    }

    return `${uri}${params}`
  },

  getCompleteOptions(options) {
    const { data, isMultipart, method, cors } = options

    if (!isMultipart && data && method !== 'GET') {
      options.body = this.getDataAsJson(data)
    }

    if (cors) {
      options.mode = 'cors'
    }

    return merge(this.getDefaultOptions(options), options, { method })
  },

  async checkResponse(res, options) {
    if (res.ok) {
      if (options.dontParse || res.status === 204) {
        return res
      }
      res = await res.json()

      if (res.error && !options.ignoreError) {
        throw parseResError(res)
      } else {
        return res
      }
    } else {
      let resJSON
      let unparsedError

      try {
        resJSON = await res.json()
        if (resJSON.error || resJSON.errors) {
          resJSON.response = { status: res.status }
        }
      } catch (err) {
        // don't care about the err
        unparsedError = new Error(res.statusText)
        unparsedError.response = res
      }

      if (resJSON) {
        throw resJSON
      } else {
        throw unparsedError
      }
    }
  },

  fetch(uri, options) {
    const { state } = options
    options.data = merge({}, options.data, getUserTokensFromState(state))

    if (state && state.currentUser) {
      const {
        data: { library_lang },
        auth,
      } = state.currentUser
      if (!auth && Boolean(library_lang)) {
        options.data = merge(options.data, { lang: library_lang })
      }
    }

    options = this.getCompleteOptions(this.normalizeFetchMethod(options))
    uri = this.getCompleteUri(uri, options)
    options = this.withoutExtraFields(options)

    return _fetch(uri, options).then(res => {
      return this.checkResponse(res, options)
    })
  },
})

function getUserTokensFromState(state) {
  const userTokenNames = ['authToken', 'revokeAccessToken']
  return pickAndMapKeys(
    {
      path: 'app',
      keys: userTokenNames,
      modifier: snakeCase,
    },
    state,
  )
}

function parseResError(res) {
  if (res.error.code) {
    res.response = { status: res.error.code }
    return res
  }

  const message = res.error.messages
    ? res.error.messages.join('. ')
    : res.error.message
  const error: CustomError = new Error(message)
  error.response = res

  return error
}

export default fetchWrapper(fetch)
