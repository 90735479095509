import React from 'react'
import urlFor, { QueryParams } from 'shared/tools/url-helper'
import Linka from 'client/shared/blocks/linka'
import { Trans } from '@lingui/react'
import { CurrentUserData } from 'client/shared/types/current-user'
import { Route } from 'client/shared/types/react-router'
import { HeaderNavigationMore } from './header-nav-more'
import { HeaderNavigationDropdown } from './header-nav-dropdown'
import { Navigation } from 'client/shared/types/showcase'
import { Location } from 'client/shared/types/react-router'

export const HeaderNavigation = ({
  auth,
  userData,
  route,
  giftsShown,
  showcaseType,
  isTabletSize,
  location,
  kind,
  query,
}: {
  auth: boolean
  userData: CurrentUserData
  route?: Route
  giftsShown?: boolean
  showcaseType: Navigation[]
  isTabletSize?: boolean
  location: Location
  kind?: string
  query?: QueryParams
}): JSX.Element => {
  const defaultClassName = 'header__nav-tab header__nav-tab_center'
  const activeClassName = 'header__nav-tab_active'
  const isFeedPage = route && route.path === '/feed'
  const className = isFeedPage
    ? `${defaultClassName} ${activeClassName}`
    : defaultClassName

  const isAccountPage = location.pathname.includes(`@${userData.login}`)
  const accountClassName = isAccountPage
    ? `${defaultClassName} ${activeClassName}`
    : defaultClassName

  // See https://bookmate.slack.com/archives/C0J91GQ59/p1613564170013800 for reasons
  const isSplitLibrary = showcaseType.length > 1
  const withComics =
    showcaseType[showcaseType.length - 1]?.params?.content_type === 'comicbook'
  const hasBooksMixed =
    isSplitLibrary && showcaseType[0]?.params?.content_type === 'mixed'
  let hasModule
  if (isSplitLibrary && showcaseType[1]?.params?.content_type === 'mixed')
    hasModule = {
      title: showcaseType[1].title,
      slug: showcaseType[1].params.slug,
    }

  if (kind === 'family') {
    return (
      <div className="header-nav__links">
        <Linka
          className="header__nav-tab header__nav-tab_center"
          path={urlFor.library(query)}
          activeClassName="header__nav-tab_active"
        >
          <Trans id="header.library_link" />
        </Linka>
        <Linka
          className="header__nav-tab header__nav-tab_center"
          path={urlFor.search(query)}
          activeClassName="header__nav-tab_active"
        >
          <Trans id="header.search_link" />
        </Linka>
      </div>
    )
  }

  return (
    <div className="header-nav__links">
      <Linka
        className="header__nav-tab header__nav-tab_center"
        path={urlFor.search(query)}
        activeClassName="header__nav-tab_active"
      >
        <Trans id="header.search_link" />
      </Linka>
      {!isSplitLibrary ? (
        <Linka
          className="header__nav-tab header__nav-tab_center"
          path={urlFor.library(query)}
          activeClassName="header__nav-tab_active"
        >
          <Trans id="header.library_link" />
        </Linka>
      ) : (
        <>
          <Linka
            className="header__nav-tab header__nav-tab_center"
            path={
              hasBooksMixed
                ? urlFor.library(query)
                : urlFor.showcaseBooks(query)
            }
            activeClassName="header__nav-tab_active"
          >
            <Trans
              id={hasBooksMixed ? 'header.library_link' : 'miscellaneous.books'}
            />
          </Linka>
          {hasModule ? (
            <Linka
              className="header__nav-tab header__nav-tab_center"
              path={urlFor.kids(query)}
              activeClassName="header__nav-tab_active"
            >
              {hasModule.title}
            </Linka>
          ) : (
            <Linka
              className="header__nav-tab header__nav-tab_center"
              path={urlFor.showcaseAudiobooks(query)}
              activeClassName="header__nav-tab_active"
            >
              <Trans id="miscellaneous.audiobooks" />
            </Linka>
          )}
          {withComics && (
            <Linka
              className="header__nav-tab header__nav-tab_center"
              path={urlFor.showcaseComicbooks(query)}
              activeClassName="header__nav-tab_active"
            >
              <Trans id="miscellaneous.comicbooks" />
            </Linka>
          )}
        </>
      )}
      {auth && (
        <Linka
          className={accountClassName}
          path={urlFor.userBooksAll(userData.login, query)}
          activeClassName="header__nav-tab_active"
        >
          <Trans id="header.my_library_link" />
        </Linka>
      )}
      {isTabletSize ? (
        <HeaderNavigationDropdown {...{ className, auth, giftsShown, query }} />
      ) : (
        <HeaderNavigationMore {...{ className, auth, giftsShown, query }} />
      )}
    </div>
  )
}
