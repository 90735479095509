import { Dispatch } from 'shared/types/redux'
import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'
import qs from 'query-string'
import { replace } from 'react-router-redux'
import mapValues from 'lodash/mapValues'
import { keyBy } from 'lodash/fp'
import { QueryParams, ALLOWED_STORED_QUERY } from 'shared/tools/url-helper'

type Size = 'desktopLarge' | 'desktopMedium' | 'desktop' | 'tablet' | 'mobile'

const FIRST_RENDER_END = 'FIRST_RENDER_END'
const DISPLAY_RESIZE = 'DISPLAY_RESIZE'
const BOOK_SHOWN = 'BOOK_SHOWN'
const LOCATION_HREF = 'LOCATION_HREF'
const SET_UTM_PARAMS = 'SET_UTM_PARAMS'
const CLEAR_TOKENS = 'CLEAR_TOKENS'
const STORE_TOKEN = 'STORE_TOKEN'
const STORE_QUERY = 'STORE_QUERY'
const SET_FINGERPRINT = 'SET_FINGERPRINT'

const LOAD_FEATURE_FLAGS = 'LOAD_FEATURE_FLAGS'
const LOAD_FEATURE_FLAGS_SUCCESS = 'LOAD_FEATURE_FLAGS_SUCCESS'
const LOAD_FEATURE_FLAGS_ERROR = 'LOAD_FEATURE_FLAGS_ERROR'

export const EXTERNAL_REDIRECT = 'EXTERNAL_REDIRECT'

const usedFeatureFlags = []

type FirstRenderEndAction = {
  type: typeof FIRST_RENDER_END
}

type StoreTokenAction = {
  type: typeof STORE_TOKEN
  token: string
}

type StoreQueryAction = {
  type: typeof STORE_QUERY
  query?: QueryParams
}

type ExternalRedirectAction = {
  type: typeof EXTERNAL_REDIRECT
  status: number
  location: string
  replace: boolean
}

type FeatureFlagsAction = {
  type: typeof LOAD_FEATURE_FLAGS_SUCCESS
  features: { name: string; enabled: boolean }[]
}

type FeatureFlagsErrorAction = {
  type: typeof LOAD_FEATURE_FLAGS_ERROR
}

type DisplayResizeAction = {
  type: typeof DISPLAY_RESIZE
  size: Size
}

type BookShownAction = {
  type: typeof BOOK_SHOWN
  count: number
}

type SetUTMParamsAction = {
  type: typeof SET_UTM_PARAMS
  utmParams: UtmParams
}

type LocationHrefAction = {
  type: typeof LOCATION_HREF
  data: string
}
type FingerprintSetAction = {
  type: typeof SET_FINGERPRINT
  fingerprint: string
}

type ClearTokensAction = {
  type: typeof CLEAR_TOKENS
}

type Action =
  | FirstRenderEndAction
  | FeatureFlagsErrorAction
  | FeatureFlagsAction
  | DisplayResizeAction
  | BookShownAction
  | LocationHrefAction
  | ExternalRedirectAction
  | SetUTMParamsAction
  | FingerprintSetAction
  | ClearTokensAction
  | StoreTokenAction
  | StoreQueryAction

type StateFromServerRender = {
  headers: {
    [key: string]: string
  }
  protocol: string
  host: string
  hostname: string
  domain: string
  url: string
  query: {
    [key: string]: string
  }
  cookies: {
    [key: string]: string
  }
  connection: {
    remoteAddress: string
  }
  csrfToken: string
  resStatus: number
}

export type UtmParams = {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
}

type InitialState = {
  firstRender: boolean
  pointName: string
  requestId: string
  headers: {
    [key: string]: string
  }
  size: Size
  os: string
  browser: string
  signInToken: string
  accessToken: string
  authToken: string
  revokeAccessToken: string
  fingerprint: string
  mobileOS: boolean
  bookShownCount: number
  currentLocationHref: string
  previousLocationHref: string
  isBookmateApp: boolean
  utmParams: UtmParams
  ua: Record<string, any>
  storedToken?: string
  storedQuery?: QueryParams
}

type HydratedState = InitialState & StateFromServerRender

export type FeatureFlags = Record<string, boolean>

export type State = HydratedState

export const initialState = {
  firstRender: true,
  pointName: '',
  requestId: '',
  headers: {},
  size: 'desktop',
  os: '',
  browser: '',
  signInToken: '',
  accessToken: '',
  authToken: '',
  revokeAccessToken: '',
  fingerprint: '',
  mobileOS: false,
  bookShownCount: 0,
  currentLocationHref: '',
  previousLocationHref: '',
  isBookmateApp: false,
  utmParams: {},
  featureFlags: {},
  ua: null,
}

export function markFirstRender(): FirstRenderEndAction {
  return {
    type: FIRST_RENDER_END,
  }
}

export function loadFeatureFlags(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/features?${qs.stringify(
        {
          names: usedFeatureFlags,
        },
        { arrayFormat: 'bracket' },
      )}`,
      types: [
        LOAD_FEATURE_FLAGS,
        LOAD_FEATURE_FLAGS_SUCCESS,
        LOAD_FEATURE_FLAGS_ERROR,
      ],
    },
  }
}

export function resetDisplaySize(size: Size): DisplayResizeAction {
  return {
    type: DISPLAY_RESIZE,
    size,
  }
}

export function setCurrentLocationHref(href: string): LocationHrefAction {
  return {
    type: LOCATION_HREF,
    data: href,
  }
}

export function externalRedirect(
  location: string,
  { status = 302, replace: isReplace = false } = {},
): ExternalRedirectAction {
  return {
    type: EXTERNAL_REDIRECT,
    status,
    location,
    replace: isReplace,
  }
}

export function setUtmParams(utmParams: UtmParams) {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: SET_UTM_PARAMS,
      utmParams,
    })
  }
}

export function setFingerprint(fingerprint: string) {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: SET_FINGERPRINT,
      fingerprint,
    })
  }
}

export function storeQuery(query?: QueryParams) {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: STORE_QUERY,
      query,
    })
  }
}

export function storeToken(token: string) {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: STORE_TOKEN,
      token,
    })
  }
}

export function clearTokens(path: string) {
  return (dispatch: Dispatch): void => {
    dispatch(replace(`${path.replace(/sign_in_token=\w+&?/, '')}`))
    dispatch({
      type: CLEAR_TOKENS,
    })
  }
}

export default function app(
  state: InitialState | HydratedState = initialState,
  action: Action,
) {
  switch (action.type) {
    case FIRST_RENDER_END:
      return {
        ...state,
        firstRender: false,
        hostname: state.hostname?.includes('rus')
          ? 'ru.bookmate.com'
          : state.hostname,
        host: state.host?.includes('rus') ? 'ru.bookmate.com' : state.host,
      }

    case LOAD_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        featureFlags: mapValues(keyBy('name', action.features), 'enabled'),
      }

    case LOAD_FEATURE_FLAGS_ERROR:
      return { ...state, featureFlags: initialState.featureFlags }

    case DISPLAY_RESIZE:
      return {
        ...state,
        size: action.size,
      }

    case BOOK_SHOWN:
      return {
        ...state,
        bookShownCount: action.count,
      }

    case LOCATION_HREF:
      return {
        ...state,
        previousLocationHref: state.currentLocationHref,
        currentLocationHref: action.data,
      }

    case SET_UTM_PARAMS:
      return {
        ...state,
        utmParams: action.utmParams,
      }

    case SET_FINGERPRINT:
      return {
        ...state,
        fingerprint: action.fingerprint,
      }

    case CLEAR_TOKENS:
      return {
        ...state,
        signInToken: '',
        accessToken: '',
      }

    case STORE_TOKEN:
      return {
        ...state,
        storedToken: action.token,
      }

    case STORE_QUERY: {
      if (!Object.entries) return state
      const storedQuery = Object.fromEntries(
        Object.entries(action.query).filter(([key]) =>
          ALLOWED_STORED_QUERY.includes(key),
        ),
      )
      return {
        ...state,
        storedQuery,
      }
    }

    default:
      return state
  }
}
