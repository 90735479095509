import find from 'lodash/find'
import React from 'react'

import urlFor, { QueryParams } from 'shared/tools/url-helper'

import { State as SubscriptionState } from 'client/bookmate/reducers/subscription-reducer'
import {
  AccessLevels,
  RecurrentSubscription,
  Product,
  ProductPlan,
  SpecialOfferPlan,
  SpecialOfferError,
  Offer,
} from 'client/shared/types/subscription'
import { Locale } from 'client/shared/types/current-user'
import { CURRENCIES_ENFORCE_CODE_FOR } from '../constants/text-constants'
import { GiftPlan } from 'client/bookmate/reducers/gifts-reducer'

export default {
  specialOffersNames: {},

  readablePrice({
    locale,
    currency,
    currencyDisplay = 'symbol',
    price,
  }: {
    locale: string
    currency?: string
    currencyDisplay?: string
    price: number
  }): string {
    price /= 100 // back end returns all prices multiplied by 100

    const isPriceFractionPresent = price - Math.floor(price) > 0

    const currencyDisplayValue =
      currency && CURRENCIES_ENFORCE_CODE_FOR.includes(currency)
        ? 'code'
        : currencyDisplay

    let options
    if (isPriceFractionPresent) {
      options = {
        style: 'currency',
        currency,
        currencyDisplay: currencyDisplayValue,
      }
    } else {
      options = {
        style: 'currency',
        currency,
        currencyDisplay: currencyDisplayValue,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }
    }

    return new Intl.NumberFormat(locale, currency ? options : {}).format(price)
  },

  specialOfferPrice(
    subscription: SubscriptionState,
    locale: Locale,
    name: string,
  ): string {
    const { plan: specialOfferPlan } = subscription.specialOffer[name]
    if (!specialOfferPlan) return ''

    return this.readablePrice({
      locale,
      currency: specialOfferPlan.currency,
      price: specialOfferPlan.price,
    })
  },

  isAutorenewableRecurrent(subscription: RecurrentSubscription): boolean {
    return subscription.autorenew_enabled
  },

  isAppleRecurrentSubscription(subscription: RecurrentSubscription): boolean {
    return subscription?.system === 'inapp'
  },

  isAndroidRecurrentSubscription(subscription: RecurrentSubscription): boolean {
    return ['android_inapp', 'androidinapp'].includes(subscription?.system)
  },

  isUncancellableBundle(subscription: RecurrentSubscription): boolean {
    return (
      this.isYouseeSubscription(subscription) ||
      this.isTelmoreSubscription(subscription) ||
      this.isPlusbogSubscription(subscription) ||
      this.isTelenorMontenegroSubscription(subscription) ||
      this.isPersonalArgentinaSubscription(subscription)
    )
  },

  isStripeSubscription(subscription: RecurrentSubscription): boolean {
    return subscription?.system === 'cards'
  },

  isNonExpiredSubscription(subscription: RecurrentSubscription): boolean {
    return Boolean(
      subscription.next_billing_at &&
        subscription.next_billing_at * 1000 > new Date().getTime(),
    )
  },

  isYouseeSubscription(subscription: RecurrentSubscription): boolean {
    return subscription?.system === 'youseebundlerecurrent'
  },

  isTelmoreSubscription(subscription: RecurrentSubscription): boolean {
    return subscription?.system === 'telmorebundlerecurrent'
  },

  isPlusbogSubscription(subscription: RecurrentSubscription): boolean {
    return subscription?.system === 'plusbogbundlerecurrent'
  },

  isTelenorMontenegroSubscription(
    subscription: RecurrentSubscription,
  ): boolean {
    return subscription?.system === 'telenor_montenegro'
  },

  isPersonalArgentinaSubscription(
    subscription: RecurrentSubscription,
  ): boolean {
    return subscription?.system === 'personal_argentina'
  },

  hasAnySubscriptions(userAccessLevels: AccessLevels): boolean {
    return (
      this.hasActiveSubscriptions(userAccessLevels) ||
      this.hasOngoingRecurrents(userAccessLevels)
    )
  },

  // this method tells us whether the user has any subscriptions that currently
  // allow them to read/listen to something
  hasActiveSubscriptions(userAccessLevels: AccessLevels): boolean {
    return userAccessLevels.expiration_dates.some(
      ({ expires_at }) => expires_at * 1000 > Date.now(),
    )
  },

  // this method tells us whether the user has any "active" (let's call them 'ongoing')
  // recurrent subscriptions that may or may not currently allow the user to read/listen
  // to something (e.g. the subscription may have expired, but it still can be renewed)
  hasOngoingRecurrents(userAccessLevels: AccessLevels): boolean {
    return userAccessLevels.active_recurrents.length > 0
  },

  hasActiveYouseeSubscription(userAccessLevels: AccessLevels): boolean {
    return userAccessLevels.active_recurrents.some(
      ({ system }) => system === 'youseebundlerecurrent',
    )
  },

  isFeaturedProductAvailable(subscription: SubscriptionState): boolean {
    return Boolean(subscription.featuredProduct)
  },

  isProductUpgradable(product: Product): boolean {
    return typeof product.upgradeable_from === 'string'
  },

  getPreselectedPlan(
    plans: ProductPlan[],
    _duration?: string,
  ): ProductPlan | null | undefined {
    const duration = _duration === 'month' ? '1_month' : '1_year'
    return find(
      plans,
      plan => plan?.system === 'cards' && plan.duration === duration,
    )
  },

  hasBilledRecurrent(recurrents: RecurrentSubscription[]): boolean {
    return (
      recurrents.length > 0 &&
      recurrents.filter(recurrent => recurrent.autorenew_enabled).length > 0
    )
  },

  hasRecurrentRenewalFailed(
    recurrentSubscription: RecurrentSubscription,
  ): boolean {
    const { subscribed_till } = recurrentSubscription
    if (!subscribed_till) return false

    const expiryDate = subscribed_till * 1000 // seconds to milliseconds
    return Date.now() - expiryDate > 0 // expiry date is in the past
  },

  getSubscriptionPagePath(
    userAccessLevels: AccessLevels,
    query?: QueryParams,
  ): string {
    return this.hasAnySubscriptions(userAccessLevels)
      ? urlFor.manageSubscriptions(query)
      : urlFor.subscription('', query)
  },

  getCurrentSpecialOffer(
    specialOffer: {
      [key: string]: {
        plan: SpecialOfferPlan | null | undefined
        error: SpecialOfferError | null | undefined
      }
    },
    name: string,
  ): Offer {
    const current = specialOffer[name]

    return (
      current || {
        plan: null,
        error: null,
      }
    )
  },

  isMasterProduct(product: Product): boolean {
    return product.id === 'master'
  },

  isFamilyProduct(product: Product): boolean {
    return product.id === 'family'
  },

  isMonthly(product: Product | ProductPlan): boolean {
    return product.duration === '1_month'
  },

  isYearly(product: Product | ProductPlan): boolean {
    return product.duration === '1_year'
  },

  isMasterPlan(plan: ProductPlan): boolean {
    return plan.productId === 'master'
  },

  isFamilyPlan(plan: ProductPlan): boolean {
    return plan.productId === 'family'
  },

  isCardsPlan(item: RecurrentSubscription | ProductPlan | GiftPlan): boolean {
    return item?.system === 'cards'
  },

  getSystemName(recurrent: RecurrentSubscription): string {
    if (this.isAndroidRecurrentSubscription(recurrent)) return 'Google Store'
    else if (this.isAppleRecurrentSubscription(recurrent)) return 'AppStore'
    else if (this.isStripeSubscription(recurrent)) return 'Stripe'
    return ''
  },
}

export const SubscriptionContext = React.createContext('plans')
