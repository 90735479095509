import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  shouldShowPreSignupFields,
  shouldAllowAuth,
  isUserRegistered,
  isUserRegistrationChecked,
} from 'client/shared/selectors/auth-selectors'

import * as authActions from 'client/shared/reducers/auth-reducer'

import LoginForm from 'client/shared/blocks/login-form'

import { Dispatch, State } from 'shared/types/redux'
import { State as AuthState } from 'client/shared/reducers/auth-reducer'
import { CurrentUserState } from 'client/shared/types/current-user'
import { handleRecaptсhaV3 } from '../helpers/recaptcha-helpers'

type Props = {
  dispatch: Dispatch
  auth: AuthState
  currentUser: CurrentUserState
  shouldShowPreSignupFields: boolean
  shouldAllowAuth: boolean
  isUserRegistrationChecked: boolean
  isUserRegistered: boolean
  withAuth: boolean
  autoFocus?: boolean
}

class LoginFormBox extends Component<Props> {
  static defaultProps = {
    withAuth: false,
    autoFocus: false,
  }

  componentWillUnmount() {
    this.props.dispatch(authActions.resetCredential())
  }

  onCredentialChange = () => {
    const {
      dispatch,
      auth: { userIsRegistered },
    } = this.props

    if (userIsRegistered) {
      dispatch(authActions.resetCredential())
    }
  }

  onCredentialEntered = (e: Event) => {
    const input = e.currentTarget

    if (!(input instanceof HTMLInputElement)) return

    const credential = input.value
    if (typeof credential === 'string' && credential.length) {
      handleRecaptсhaV3('check_user_exists', token => {
        this.props.dispatch(
          authActions.checkIfUserIsRegistered(
            { credential, channel: 'email' },
            token,
          ),
        )
      })
    }
  }

  onBrowserEntered = (email: string) => {
    if (!email) return

    if (typeof email === 'string' && email.length) {
      handleRecaptсhaV3('check_user_exists', token => {
        this.props.dispatch(
          authActions.checkIfUserIsRegistered(
            { credential: email, channel: 'email' },
            token,
          ),
        )
      })
    }
  }

  onSocialButtonClick = provider => {
    this.props.dispatch(authActions.initSocialAuth(provider))
  }

  changeStep = step => {
    this.props.dispatch(authActions.changeStep(step))
  }

  render() {
    const {
      dispatch,
      currentUser: {
        data: { country },
      },
      auth: {
        currentStep,
        socialAuth,
        error,
        loading,
        ui: { checkingIfRegistered },
        userpic,
      },
      // eslint-disable-next-line no-shadow
      shouldShowPreSignupFields,
      // eslint-disable-next-line no-shadow
      shouldAllowAuth,
      // eslint-disable-next-line no-shadow
      isUserRegistrationChecked,
      // eslint-disable-next-line no-shadow
      isUserRegistered,
      withAuth,
      autoFocus,
    } = this.props

    return (
      <LoginForm
        autoFocus={autoFocus}
        dispatch={dispatch}
        withAuth={withAuth}
        currentStep={currentStep}
        socialAuth={socialAuth}
        shouldShowPreSignupFields={shouldShowPreSignupFields}
        shouldAllowAuth={shouldAllowAuth}
        loading={loading}
        error={error}
        changeStep={this.changeStep}
        isUserRegistrationChecked={isUserRegistrationChecked}
        isUserRegistered={isUserRegistered}
        country={country}
        onSocialButtonClick={this.onSocialButtonClick}
        credentialInputProps={{
          loading: checkingIfRegistered,
          userpic,
          onChange: this.onCredentialChange,
          onBlur: this.onCredentialEntered,
          onBrowserEntered: this.onBrowserEntered,
        }}
      />
    )
  }
}

export default connect((state: State) => ({
  auth: state.auth,
  currentUser: state.currentUser,
  shouldShowPreSignupFields: shouldShowPreSignupFields(state),
  shouldAllowAuth: shouldAllowAuth(state),
  isUserRegistrationChecked: isUserRegistrationChecked(state),
  isUserRegistered: isUserRegistered(state),
}))(LoginFormBox)
