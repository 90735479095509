import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'
import { Dispatch } from 'shared/types/redux'
import { showAlert } from 'client/shared/reducers/alert-reducer'
import { externalRedirect } from '../../shared/reducers/app-reducer'
import { push } from 'react-router-redux'
import subscriptionHelper from 'client/shared/helpers/subscription-helper'
import {
  SUBSCRIPTION_INITIATED,
  SUBSCRIPTION_SUCCESSFUL,
  analyticsEvent,
} from 'client/shared/reducers/analytics-reducer'

import { cleanShowcase } from './showcase-reducer'
import {
  loadContext,
  loadInfo,
  USER_ID,
} from '../../shared/reducers/current-user-reducer'
import env from '../../../env'
import { addParamsToPath, getDomain } from '../../../shared/tools/url-helper'
import cookie from 'react-cookie'
import { hide } from '../../shared/reducers/popup-reducer'

export const MTN_SET_PHONE_NUMBER = 'MTN_SET_PHONE_NUMBER'
export const MTN_SET_PLAN = 'MTN_SET_PLAN'

const MTN_PHONE_SHORT_AUTH = 'MTN_PHONE_SHORT_AUTH'
const MTN_PHONE_SHORT_AUTH_SUCCESS = 'MTN_PHONE_SHORT_AUTH_SUCCESS'
const MTN_PHONE_SHORT_AUTH_ERROR = 'MTN_PHONE_SHORT_AUTH_ERROR'

const MTN_PHONE_VERIFICATION = 'MTN_PHONE_VERIFICATION'
const MTN_PHONE_VERIFICATION_SUCCESS = 'MTN_PHONE_VERIFICATION_SUCCESS'
const MTN_PHONE_VERIFICATION_ERROR = 'MTN_PHONE_VERIFICATION_ERROR'

const MTN_ACTIVATE_SUBSCRIPTION = 'MTN_ACTIVATE_SUBSCRIPTION'
const MTN_ACTIVATE_SUBSCRIPTION_SUCCESS = 'MTN_ACTIVATE_SUBSCRIPTION_SUCCESS'
const MTN_ACTIVATE_SUBSCRIPTION_ERROR = 'MTN_ACTIVATE_SUBSCRIPTION_ERROR'

const MTN_PAYMENT_INITIATE = 'MTN_PAYMENT_INITIATE'
const MTN_PAYMENT_INITIATE_SUCCESS = 'MTN_PAYMENT_INITIATE_SUCCESS'
const MTN_PAYMENT_INITIATE_ERROR = 'MTN_PAYMENT_INITIATE_ERROR'

const SET_MTN_HEADER_PHONE = 'SET_MTN_HEADER_PHONE'
// const GET_MTN_HEADER_PHONE_THUNK = 'GET_MTN_HEADER_PHONE_THUNK'
const MTN_CHECK_STATUS = 'MTN_CHECK_STATUS'
const MTN_CHECK_STATUS_SUCCESS = 'MTN_CHECK_STATUS_SUCCESS'
const MTN_CHECK_STATUS_ERROR = 'MTN_CHECK_STATUS_ERROR'
const MARK_AUTH = 'MARK_AUTH'
const MARK_STEP = 'MARK_STEP'
const MARK_END_AUTH = 'MARK_END_AUTH'

// eslint-disable-next-line no-shadow
export enum PlanEnum {
  day = '1_day',
  week = '1_week',
  month = '1_month',
}

type typeSetMNTShortAuthAC = {
  type: typeof MTN_PHONE_SHORT_AUTH
}

type typeSetMNTShortAuthErrorAC = {
  type: typeof MTN_PHONE_SHORT_AUTH_ERROR
}

type typeSetMNTShortAuthSussessAC = {
  type: typeof MTN_PHONE_SHORT_AUTH_SUCCESS
}

type typeSetMNTPhoneNumberAC = {
  type: typeof SET_MTN_HEADER_PHONE
  payload: { phoneNumber: string }
}

type MTNSetPhoneNumber = {
  type: typeof MTN_SET_PHONE_NUMBER
  payload: { phoneNumber: string; isMonth?: boolean }
}

type MTNSetPlan = {
  type: typeof MTN_SET_PLAN
  payload: { plan: PlanEnum; isMonth: boolean }
}

type MTNVerification = {
  type: typeof MTN_PHONE_VERIFICATION
}

type MTNVerificationSuccess = {
  type: typeof MTN_PHONE_VERIFICATION_SUCCESS
}

type MTNVerificationError = {
  type: typeof MTN_PHONE_VERIFICATION_ERROR
}

type MTNActivateSubscription = {
  type: typeof MTN_ACTIVATE_SUBSCRIPTION
}

type MTNActivateSubscriptionSuccess = {
  type: typeof MTN_ACTIVATE_SUBSCRIPTION_SUCCESS
}

type MTNActivateSubscriptionError = {
  type: typeof MTN_ACTIVATE_SUBSCRIPTION_ERROR
}

type MTNPaymentInitiate = {
  type: typeof MTN_PAYMENT_INITIATE
}

type MTNPaymentInitiateSuccess = {
  type: typeof MTN_PAYMENT_INITIATE_SUCCESS
}

type MTNPaymentInitiateError = {
  type: typeof MTN_PAYMENT_INITIATE_ERROR
}

type MTNCheckStatus = {
  type: typeof MTN_CHECK_STATUS
}

type MTNCheckStatusSuccess = {
  type: typeof MTN_CHECK_STATUS_SUCCESS
}

type MTNCheckStatusError = {
  type: typeof MTN_CHECK_STATUS_ERROR
}

type MarkAuth = {
  type: typeof MARK_AUTH
}

type MarkStep = {
  type: typeof MARK_STEP
  payload: { registered: boolean }
}

type MarkEndAuth = {
  type: typeof MARK_END_AUTH
}

type Action =
  | MTNSetPhoneNumber
  | MTNSetPlan
  | MTNVerification
  | MTNVerificationSuccess
  | MTNVerificationError
  | MTNPaymentInitiate
  | MTNPaymentInitiateSuccess
  | MTNPaymentInitiateError
  | MTNCheckStatus
  | MTNCheckStatusSuccess
  | MTNCheckStatusError
  | MTNActivateSubscription
  | MTNActivateSubscriptionSuccess
  | MTNActivateSubscriptionError
  | MarkAuth
  | MarkEndAuth
  | MarkStep
  | typeSetMNTPhoneNumberAC
  | typeSetMNTShortAuthAC
  | typeSetMNTShortAuthErrorAC
  | typeSetMNTShortAuthSussessAC

export function setMNTPhoneNumberAC(phoneNumber: string) {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: SET_MTN_HEADER_PHONE,
      payload: { phoneNumber },
    })
  }
}

export function MNTShortSignInThunk(
  phone: string,
  flow: string | undefined = undefined,
): ApiAction {
  const endpoint = '/p/api/v5/mtn_ghana/identity_sign_in'

  return {
    [CALL_API]: {
      endpoint,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: {
        method: 'post',
        ignoreError: false,
        data: {
          user: {
            phone_number: phone,
          },
        },
      },
      types: [
        MTN_PHONE_SHORT_AUTH,
        MTN_PHONE_SHORT_AUTH_SUCCESS,
        MTN_PHONE_SHORT_AUTH_ERROR,
      ],
      onSuccess: async (_dispatch: Dispatch, getState) => {
        await _dispatch({
          type: MTN_SET_PHONE_NUMBER,
          payload: { phoneNumber: phone.slice(1) },
        })

        await _dispatch(cleanShowcase())
        await _dispatch(loadContext())
        await _dispatch(loadInfo())

        await _dispatch({
          type: 'SIGN_IN_WITH_TOKEN_SUCCESS',
        })

        const { currentUser, app } = getState()

        if (!(currentUser?.auth && currentUser.auth)) {
          _dispatch(push('/mtn'))
        } else {
          const userId = currentUser.data?.id
            ? currentUser.data?.id.toString()
            : null

          const cookieOptions = {
            domain: env.isProduction() ? `.${getDomain(app.host)}` : undefined,
            path: '/',
          }

          if (userId) cookie.save(USER_ID, userId, cookieOptions)

          if (flow) cookie.save('mtn_flow', flow, cookieOptions)

          let path = '/mtn/entered/tariffs'

          if (flow === 'skip_subscription') path = '/'

          if (app.storedQuery && Object.keys(app.storedQuery).length > 0)
            path = addParamsToPath(path, app.storedQuery)

          _dispatch(push(path))
        }

        markEndAuth()
        _dispatch(hide())

        /* if (mtn?.phone) {
          const hasSubscription = subscriptionHelper.hasActiveSubscriptions(
            subscription.userAccessLevels,
          )


          if (!hasSubscription) {
            await _dispatch({
              type: MTN_SET_PLAN,
              payload: { plan: PlanEnum.day, isMonth: false },
            })
            await _dispatch(checkStatus(phone))

          } else {
            _dispatch(push('/'))
          }
        } */
      },
      onError: (_dispatch: Dispatch, _, response) => {
        _dispatch(showAlert('error', { message: response.error }))
      },
    },
  }
}

const sendStatistic = (txid: string) => {
  const path = `https://m.m2888.net/c/p/78a1fd2a638d42918f6403e6550027a1?txid=${txid}`
  fetch(path).then()
}

export function markAuth() {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: MARK_AUTH,
    })
  }
}

export function markStep(registered: boolean) {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: MARK_STEP,
      payload: { registered },
    })
  }
}

export function markEndAuth() {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: MARK_END_AUTH,
    })
  }
}

export function verifyPhone(phone: string): ApiAction {
  const endpoint = '/p/api/v5/mtn_ghana/phones/registered'
  return {
    [CALL_API]: {
      endpoint,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: {
        data: {
          phone_number: phone,
        },
      },
      types: [
        MTN_PHONE_VERIFICATION,
        MTN_PHONE_VERIFICATION_SUCCESS,
        MTN_PHONE_VERIFICATION_ERROR,
      ],
      onSuccess: (_dispatch: Dispatch, _getState, response) => {
        const state = _getState()
        const { currentUser, subscription, app } = state
        const hasSubscription = subscriptionHelper.hasActiveSubscriptions(
          subscription.userAccessLevels,
        )
        const isAuth = currentUser?.auth
        if (isAuth) {
          let path = '/mtn/entered/'
          if (hasSubscription) {
            path += 'success'
          } else {
            path += 'tariffs'
            if (app.storedQuery && Object.keys(app.storedQuery).length > 0)
              path = addParamsToPath(path, app.storedQuery)
          }
          return _dispatch(push(path))
        } else {
          _dispatch(markAuth())
          _dispatch(markStep(response.registered))
          const path =
            app.storedQuery && Object.keys(app.storedQuery).length > 0
              ? addParamsToPath('/mtn/entered/login', app.storedQuery)
              : '/mtn/entered/login'
          _dispatch(push(path))
        }
      },
      onError: (_dispatch: Dispatch, getState, response) => {
        _dispatch(showAlert('error', { message: response.error }))
      },
    },
  }
}

export function activateSubscription(phone: string): ApiAction {
  const endpoint = '/p/api/v5/mtn_ghana/subscriptions/activate_web'

  return {
    [CALL_API]: {
      endpoint,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: {
        method: 'post',
        data: {
          phone_number: phone,
        },
      },
      types: [
        MTN_ACTIVATE_SUBSCRIPTION,
        MTN_ACTIVATE_SUBSCRIPTION_SUCCESS,
        MTN_ACTIVATE_SUBSCRIPTION_ERROR,
      ],
      onSuccess: (_dispatch: Dispatch) => {
        _dispatch(
          analyticsEvent(SUBSCRIPTION_SUCCESSFUL, {
            payment_method: 'mtn',
          }),
        )
      },
    },
  }
}

export function initiatePayment(plan: PlanEnum, phone: string): ApiAction {
  const endpoint = '/p/api/v5/mtn_ghana/subscriptions'

  return {
    [CALL_API]: {
      endpoint,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: {
        method: 'post',
        data: {
          plan,
          phone_number: phone,
        },
      },
      types: [
        MTN_PAYMENT_INITIATE,
        MTN_PAYMENT_INITIATE_SUCCESS,
        MTN_PAYMENT_INITIATE_ERROR,
      ],
      onSuccess: (_dispatch: Dispatch, _getState, response) => {
        _dispatch(
          analyticsEvent(SUBSCRIPTION_INITIATED, {
            sub_type: 'mtn',
          }),
        )
        if (response.success) {
          _dispatch(push('/mtn/entered/payment'))
          _dispatch(checkStatus(phone))
        } else {
          _dispatch(externalRedirect('/mtn/entered/404', { replace: true }))
        }
      },
      onError: (_dispatch: Dispatch, getState, response) => {
        _dispatch(showAlert('error', { message: response.error }))
        _dispatch(externalRedirect('/mtn/entered/404', { replace: true }))
      },
    },
  }
}

export function checkStatus(phone: string): ApiAction {
  const endpoint = '/p/api/v5/mtn_ghana/subscriptions/state'

  return {
    [CALL_API]: {
      endpoint,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options: {
        data: {
          phone_number: phone,
        },
      },
      types: [
        MTN_CHECK_STATUS,
        MTN_CHECK_STATUS_SUCCESS,
        MTN_CHECK_STATUS_ERROR,
      ],
      onSuccess: (_dispatch: Dispatch, _getState, response) => {
        const { app } = _getState()

        if (response.success === 'error') {
          _dispatch(externalRedirect('/mtn/entered/404', { replace: true }))
        }

        if (response.state === 'pending') {
          setTimeout(() => _dispatch(checkStatus(phone)), 1500)
        }

        if (response.state === 'active') {
          if (app.storedQuery && 'click_id' in app.storedQuery) {
            sendStatistic(app.storedQuery.click_id.toString())
          }
          _dispatch(activateSubscription(phone))
          _dispatch(push('/mtn/entered/success'))
        }

        if (response.state === 'failed') {
          _dispatch(showAlert('error', { message: response.message }))
          _dispatch(externalRedirect('/mtn/entered/404', { replace: true }))
        }
      },
      onError: (_dispatch: Dispatch, getState, response) => {
        if (response.response?.status === 404 || response.status === 404) {
          const { plan } = getState().mtn
          _dispatch(initiatePayment(plan, phone))
        } else {
          _dispatch(showAlert('error', { message: response.error }))
          _dispatch(externalRedirect('/mtn/entered/404', { replace: true }))
        }
      },
    },
  }
}

export type MTNState = {
  plan: PlanEnum
  phone: string
  loading: boolean
  authInit?: boolean
  isMonth?: boolean
  registered?: boolean
  headerPhone?: string
}

const initialState = {
  phone: '',
  plan: PlanEnum.day,
  loading: false,
  isMonth: false,
}

export default function MTNReducer(
  state: MTNState = initialState,
  action: Action,
): MTNState {
  switch (action.type) {
    case SET_MTN_HEADER_PHONE:
      return {
        ...state,
        headerPhone: action.payload?.phoneNumber,
      }
    case MARK_STEP:
      return {
        ...state,
        registered: action.payload?.registered,
      }
    case MARK_AUTH:
      return {
        ...state,
        authInit: true,
      }
    case MARK_END_AUTH:
      return {
        ...state,
        authInit: false,
      }
    case MTN_SET_PHONE_NUMBER:
      return {
        ...state,
        phone: action.payload.phoneNumber,
        isMonth: action.payload.isMonth || false,
      }
    case MTN_SET_PLAN:
      return {
        ...state,
        plan: action.payload.plan,
        isMonth: action.payload.isMonth,
      }
    case MTN_PHONE_VERIFICATION:
    case MTN_PHONE_SHORT_AUTH:
    case MTN_PAYMENT_INITIATE:
      return {
        ...state,
        loading: true,
      }
    case MTN_PHONE_VERIFICATION_SUCCESS:
    case MTN_PHONE_VERIFICATION_ERROR:
    case MTN_PAYMENT_INITIATE_SUCCESS:
    case MTN_PHONE_SHORT_AUTH_SUCCESS:
    case MTN_PHONE_SHORT_AUTH_ERROR:
    case MTN_PAYMENT_INITIATE_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
