import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { show as showPopup } from 'client/shared/reducers/popup-reducer'

import Button from 'client/shared/blocks/button'

type Props = {
  type: 'link' | 'button'
  messageId: string
  message?: string
  buttonProps?: {
    width?: string
    className?: string
  }
} & DecoratorProps

class LoginButton extends Component<Props> {
  static defaultProps = {
    type: 'link',
    messageId: 'header.login_link',
  }

  handleClick = () => {
    const { dispatch } = this.props
    dispatch(showPopup())
  }

  render() {
    if (this.props.type === 'button') {
      return this.renderButton()
    } else {
      return this.renderLink()
    }
  }

  renderLink() {
    return (
      <div className="login-button" onClick={this.handleClick}>
        {this.renderMessage()}
      </div>
    )
  }

  renderButton() {
    return (
      <Button
        {...this.props.buttonProps}
        type="inline"
        onClick={this.handleClick}
      >
        {this.renderMessage()}
      </Button>
    )
  }

  renderMessage() {
    const { message, messageId } = this.props

    if (message) {
      return <span>{message}</span>
    } else {
      return <Trans id={messageId} />
    }
  }
}

export default uiBox(LoginButton)
